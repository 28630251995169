.plogo-wrapper {
  width: 1024px;
  height: 1024px;
  margin: 0;
  border-radius: 100%;
  background-color: #28A7E7;
  overflow: hidden;
  position: absolute;
  top: 0;
  left: 0;
}
.anim-pyraplayer-logo {
  -webkit-transition: all 0.7s;
  -moz-transition: all 0.7s;
  -ms-transition: all 0.7s;
  -o-transition: all 0.7s;
  transition: all 0.7s;
  width: 1024px;
  height: 1024px;
  background-color: white;
  mask-image: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iNDYyIiBoZWlnaHQ9IjU3MyIgdmlld0JveD0iMCAwIDQ2MiA1NzMiIGZpbGw9Im5vbmUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+CjxwYXRoIGQ9Ik0xMTguMDU2IDU1NC4xMzFDNzAuNjA2OSA1OTcuMzg4IC01LjE4Mjc3IDU1OS4yNzUgMS4yNDc5MSA0OTUuMzlMNDQuNjg1NyA2My44NjIzQzQ5LjYwMTEgMTUuMDMwNiAxMDEuOTM2IC0xMy43MTQgMTQ1Ljc4MyA4LjMzNTQ1TDQyMi44NTIgMTQ3LjY2OEM0NjYuNjk5IDE2OS43MTcgNDc0LjgzMiAyMjguODcgNDM4LjU2MyAyNjEuOTM1TDExOC4wNTYgNTU0LjEzMVoiIGZpbGw9IndoaXRlIi8+Cjwvc3ZnPgo=), url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjc3IiBoZWlnaHQ9IjUyOSIgdmlld0JveD0iMCAwIDI3NyA1MjkiIGZpbGw9Im5vbmUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+CjxwYXRoIGQ9Ik0yMTcuNzQxIDE1Ljc3MTRDMjM1Ljg5IC0xMy4yMTYgMjgwLjc0NCAyLjc1NTgyIDI3Ni40ODggMzYuNjg5OUwyMzMuODQ0IDQ2My4xMzFDMjMwLjkyNiA0OTIuMzA4IDIxMC4xMzIgNTE2LjU3NSAxODEuNzQ2IDUyMy45MjhMMTczLjg3MyA1MjUuOTY4QzE0OC40NjUgNTMyLjU1MSAxMjEuNTA5IDUyNC4zNjYgMTA0LjA1IDUwNC43NjhMMTguNjUyMiA0MDguOTA3Qy0zLjA4NjMzIDM4NC41MDUgLTUuMDQ3MzUgMzQ4LjMwOSAxMy45Mjc2IDMyMS43MDFMMTE0LjQ4OCAxODAuNjlMMjE3Ljc0MSAxNS43NzE0WiIgZmlsbD0id2hpdGUiLz4KPC9zdmc+Cg==);
  -webkit-mask-image: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iNDYyIiBoZWlnaHQ9IjU3MyIgdmlld0JveD0iMCAwIDQ2MiA1NzMiIGZpbGw9Im5vbmUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+CjxwYXRoIGQ9Ik0xMTguMDU2IDU1NC4xMzFDNzAuNjA2OSA1OTcuMzg4IC01LjE4Mjc3IDU1OS4yNzUgMS4yNDc5MSA0OTUuMzlMNDQuNjg1NyA2My44NjIzQzQ5LjYwMTEgMTUuMDMwNiAxMDEuOTM2IC0xMy43MTQgMTQ1Ljc4MyA4LjMzNTQ1TDQyMi44NTIgMTQ3LjY2OEM0NjYuNjk5IDE2OS43MTcgNDc0LjgzMiAyMjguODcgNDM4LjU2MyAyNjEuOTM1TDExOC4wNTYgNTU0LjEzMVoiIGZpbGw9IndoaXRlIi8+Cjwvc3ZnPgo=), url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjc3IiBoZWlnaHQ9IjUyOSIgdmlld0JveD0iMCAwIDI3NyA1MjkiIGZpbGw9Im5vbmUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+CjxwYXRoIGQ9Ik0yMTcuNzQxIDE1Ljc3MTRDMjM1Ljg5IC0xMy4yMTYgMjgwLjc0NCAyLjc1NTgyIDI3Ni40ODggMzYuNjg5OUwyMzMuODQ0IDQ2My4xMzFDMjMwLjkyNiA0OTIuMzA4IDIxMC4xMzIgNTE2LjU3NSAxODEuNzQ2IDUyMy45MjhMMTczLjg3MyA1MjUuOTY4QzE0OC40NjUgNTMyLjU1MSAxMjEuNTA5IDUyNC4zNjYgMTA0LjA1IDUwNC43NjhMMTguNjUyMiA0MDguOTA3Qy0zLjA4NjMzIDM4NC41MDUgLTUuMDQ3MzUgMzQ4LjMwOSAxMy45Mjc2IDMyMS43MDFMMTE0LjQ4OCAxODAuNjlMMjE3Ljc0MSAxNS43NzE0WiIgZmlsbD0id2hpdGUiLz4KPC9zdmc+Cg==);
  -webkit-mask-size: 460.55px 571.79px, 275.85px 527.52px;
  mask-size: 460.55px 571.79px, 275.85px 527.52px;
  -webkit-mask-repeat: no-repeat, no-repeat;
  mask-repeat: no-repeat, no-repeat;
  -webkit-mask-position: 748px -518px, -74px 958px;
  mask-position: 748px -518px, -74px 958px;
  -webkit-mask-composite: xor;
  mask-composite: xor;
}
.anim-pyraplayer-logo.animate {
  -webkit-mask-position: 374px 226px, 294px 305px;
  mask-position: 374px 226px, 294px 305px;
}
.anim-pyraplayer-logo.animate2 {
  -webkit-transition: all 1s;
  -moz-transition: all 1s;
  -ms-transition: all 1s;
  -o-transition: all 1s;
  transition: all 1s;
  -webkit-mask-position: 392.86px 175.81px, 258.92px 333.69px;
  mask-position: 392.86px 175.81px, 258.92px 333.69px;
}